import React, { FC, useState } from 'react'
import ayrtonlogo from 'assets/img/icons/my_icons/ayrtonlogo.svg'
import { Form, Label, Input, FormGroup, Button, Spinner } from 'reactstrap'
// import ModalRecover from "./ModalRecover/index2"
import { withRouter, useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'

const mapStateToProps = ({ data: { initialState, loading_initialState }, dispatch, user }) => {
  return {
    dispatch,
    loading_initialState,
    initialState,
    user,
  }
}

interface LoginPageType {
  dispatch: (p: any) => void
  loading_initialState: boolean
  initialState: any
  user: any
}

const loginPage: FC<LoginPageType> = ({ dispatch, loading_initialState, initialState, user }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')

  let { id } = useParams()
  const history = useHistory()

  // let user_data = useSelector((state) => state.user)

  const handleSignIn = async () => {
    const data = {
      username: username,
      password: password,
    }
    dispatch({
      type: 'user/LOGIN',
      payload: data,
    })
  }

  const handleNewPassword = async () => {
    const data = {
      password: password,
      confirmPassword: confirmPassword,
    }

    if (password !== confirmPassword) {
      setError('Senha não são iguais, tente novamente!')
      return
    }

    if (password.length < 8) {
      setError('Senha não segue os requerimentos de segurança.')
      return
    }

    setError(null)
    dispatch({
      type: 'user/SubmitNewPassword',
      payload: data,
    })
  }

  if (user?.authorized === true) {
    history.push(`/hotel/${id}/reservation`)
  }

  if (user.currentChallange === 'login') {
    return (
      <>
        {initialState ? (
          <div className="d-flex justify-content-center align-items-center flex-column mt-5 mx-2">
            <div className="d-flex justify-content-center align-items-center text-center text-md-left">
              <img
              //@ts-ignore
                loading="lazy"
                src={initialState?.logoURL ? initialState?.logoURL : ayrtonlogo}
                alt="Logo Cliente"
                style={{ width: 200, height: 200 }}
              />
            </div>

            <div
              style={{
                marginTop: '100px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <span
                style={{ color: '#5c3bbf', fontSize: 25, fontWeight: 'bold', textAlign: 'center' }}
              >
                Painel do proprietário
              </span>
            </div>
            <Form
              style={{
                marginTop: '30px',
                maxWidth: '300px',
                width: '100%',
              }}
            >
              <FormGroup>
                <Label for="username">Usuário</Label>
                <Input
                  type="text"
                  name="username"
                  id="username"
                  placeholder="Digite o seu nome de usuário"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  rules
                />
              </FormGroup>

              <FormGroup>
                <Label for="username">Senha</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Digite a sua senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  // onKeyDown={(e) => handleKey(e)}
                />
              </FormGroup>
            </Form>

            {user.loginErrorMsg != null ? (
              <div style={{ color: 'red' }}>{user.loginErrorMsg}</div>
            ) : null}

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px',
                width: '100%',
              }}
            >
              <Button
                onClick={handleSignIn}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '300px',
                  height: '40px',
                }}
                color="primary"
              >
                {user.loading ? <Spinner color={'#fff'} size="sm" /> : 'Entrar'}
              </Button>
            </div>
            {/* <ModalRecover /> */}
          </div>
        ) : (
          <div></div>
        )}
      </>
    )
  }

  if (user.currentChallange === 'NEW_PASSWORD_REQUIRED') {
    return (
      <>
        {initialState ? (
          <div className="d-flex justify-content-center align-items-center flex-column mt-5 mx-2">
            <div
              style={{
                flexDirection: 'column',
                marginTop: '100px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <span
                style={{ color: '#5c3bbf', fontSize: 25, fontWeight: 'bold', textAlign: 'center' }}
              >
                Nova Senha
              </span>
              <span
                style={{ color: '#000', fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}
              >
                Configure uma nova senha para ter acesso ao Painel do Proprietário.
              </span>
              <span
                style={{ color: '#000', fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}
              >
                Ter pelo menos 8 Caracteres
              </span>
            </div>
            <Form
              style={{
                marginTop: '30px',
                maxWidth: '300px',
                width: '100%',
              }}
            >
              <FormGroup>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Digite sua nova senha"
                  // value={username}
                  onChange={(e) => setPassword(e.target.value)}
                  rules
                />
              </FormGroup>

              <FormGroup>
                <Input
                  type="password"
                  name="newpassword"
                  id="newpassword"
                  placeholder="Confirme sua nova senha"
                  // value={password}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormGroup>
            </Form>

            {user.loginErrorMsg != null ? (
              <div style={{ color: 'red' }}>{user.loginErrorMsg}</div>
            ) : null}

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px',
                width: '100%',
              }}
            >
              <Button
                onClick={handleNewPassword}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '300px',
                  height: '40px',
                }}
                color="primary"
              >
                {user.loading ? <Spinner color={'#fff'} size="sm" /> : 'Enviar'}
              </Button>
            </div>
            {error ? <div style={{ color: 'red', marginTop: '10px' }}>{error}</div> : null}
          </div>
        ) : (
          <div></div>
        )}
      </>
    )
  }
}

export const LoginPage = withRouter(connect(mapStateToProps)(loginPage))
export default LoginPage
