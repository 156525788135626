import React, { Component } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Helmet } from 'react-helmet'
import fav from 'assets/teste.ico'
import { connect } from 'react-redux'
import autobind from 'class-autobind'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import './Header.css'
import logo from 'assets/img/logo.svg'
import { HELPER_IS_DNS } from 'helpers/isDNS'



const mapStateToProps = ({
    data: { hotel, initialState, loading_initialState }, user,
    dispatch
}) => {
    return {
        hotel,
        loading_initialState,
        initialState,
        dispatch,
        user,
    }
}

class header extends Component {
    state = {
        loading_initialState: true,
    }
    constructor(props) {
        super(props)
        autobind(this)
    }


    // componentWillReceiveProps(props) {
    //     this.setState({
    //     loading_initialState: props.loading_initialState,
    //     })
    // }

    // static getDerivedStateFromProps(props, state) {
    //     if (props?.loading_initialState !== state?.loading_initialState) {
    //         return this.setState({
    //             loading_initialState: props?.loading_initialState,
    //         })
    //     }
    // }

    homePage() {
        const url = HELPER_IS_DNS() ? `/` : `/hotel/${this.props.match.params.id}`
        this.props.history.replace(url)
    }

    handleLogout = async () => {
        await this.props.dispatch({
            type: 'user/LOGOUT'
        })
    }



    render() {
        // console.log('Dados iniciais header : ',this.props.initialState);


        if (this.props.user?.authorized === false) {
            const { id } = this.props.match.params;
            this.props.history.push(`/hotel/${id}/login`);

        }

        return (
            <>
                <div
                    style={{
                        backgroundColor: '#fff',
                        padding: '20px',
                        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                    }}
                >
                    {/* 
            {this.props.loading_initialState === false ? 
                (
                    <Helmet>
                        <title>{this.props.initialState.name}</title>
                    </Helmet>
                ) : 
                (
                    <Helmet>
                        <title>Reserve agora - Ayrton</title>
                    </Helmet>
                )
            } */}

                    <div className="row py-1 p-0 m-0 justify-content-between align-items-center">
                        <div className="col-5 p-0 m-0 ml-3">
                            {this.props.loading_initialState === false ?
                                (
                                    this.props.initialState.logoURL ?
                                        (
                                            <div onClick={() => this.homePage()} style={{ cursor: 'pointer' }}>
                                                <img
                                                                        loading="lazy"

                                                    style={{ width: '4rem', height: '4rem', maxWidth: '16rem' }}
                                                    src={this.props.initialState.logoURL}
                                                    alt="Logo cliente"
                                                />
                                            </div>
                                        ) :
                                        (
                                            <b>{this.props.initialState.name}</b>
                                        )
                                ) :
                                (
                                    <Skeleton></Skeleton>
                                )
                            }
                        </div>

                        <div className="row py-1 p-0 m-0 justify-content-between align-items-center">
                            <div className="col-5 p-0 m-0 ml-3">
                                {this.props.loading_initialState === false ? (
                                    this.props.initialState.whatsapp !== undefined ? (
                                        <>


                                        </>
                                    ) : (
                                        <div className="col-5 p-0 m-0 text-right mr-3">

                                        </div>
                                    )
                                ) : (
                                    <Skeleton width={50}></Skeleton>
                                )}

                            </div>
                        </div>

                        <div className="row py-1 p-0 m-0 justify-content-between align-items-center">
                            <Button
                                style={{ height: 40, width: 100 }}
                                color="primary"
                                onClick={this.handleLogout}
                            >
                                Sair
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export const Header = withRouter(connect(mapStateToProps)(header))

export default Header
